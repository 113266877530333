@import 'system/styles/funcs.scss';


.narrativeContainer{
    visibility: hidden;
    // background-color: var(--narrative-background);
}
.container{
    overflow-y: hidden;
    overflow-x: scroll;
    visibility: hidden;
    will-change: transform;
    touch-action: none;
    overscroll-behavior:none;   
}

.scrollPosition{
    position:absolute;
    display: none;
    top:0;
    width:10px;
    height: 100%;
}
:global(.ipad) .scrollPosition{
    scroll-snap-align: start;
    display: block;
 
}
.containerScroll{
    height: 100%;
    width: auto;
    position: relative;
    white-space: nowrap;
}
:global(.ipad) .containerScroll{
    scroll-snap-align: none;
}

.menuContainer{
    position: absolute;
    width:100%;
    height: 100%;
    left:0;
    top:0;
    background-color:var(--primary-background);
    --dragger-width: calc(24px * var(--scaleiPad));
    display: flex;
    filter: drop-shadow(5px 0px 5px rgba(0,0,0,0.5));
}

$menuModuleContainerWidth : scaleValue(60px,80px);
.menuContainer[overlap]{
    filter: drop-shadow(10px 3px 20px rgba(0,0,0,0.5));
}

.menuColRight{
    position: relative;
     -webkit-overflow-scrolling: touch;
    transform:translate3d(0,0,0);
    backface-visibility: hidden;
    background-color: var(--primary-background);
    height: 100%;
    max-height: 100%;;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    min-width: scaleValue(328px,461px);
    padding-right: var(--dragger-width);
    box-shadow: 20px rgba(0,0,0,0.5);
}


.menuLogo{
    object-fit: contain;
    max-height: 15%;
    width:calc(100% - 134px);
    margin: 22px 0 12px $menuModuleContainerWidth;
    object-position: center;
    display: block;
    
}

:global(.serverApp) .menuLogo{
    margin-left:32px;
}

.menuTitle{
    color:var(--primary);
    font-size: scaleValue(22px,30px);
    font-weight: 600;
    margin:  scaleValue(43px,68px) scaleValue(23px,32px) ;
}
.menuRightBg{
    position: absolute;
    pointer-events: none;
    right:0;
    width: auto;
    min-width: 100%;
    z-index:-1;
    max-width: 100%;
    height:100%;
    object-fit: cover;
}


.menuBg{
    height:100%;
    border-radius: 0  0 15vh 0;
    overflow: hidden;
    object-fit: cover;
    object-position: left center;
    width:100%;
    background-color: var(--secondary-background);
    object-fit: cover;
    object-position: center;
}

// :global(.controllerApp) .menuBg {
//     border-radius: 0;
// }

.menuRightColContainer{
    position: relative;
    top:calc(41px * var(--scale-iPad));
    align-items: flex-start;
    display: flex;
    justify-items: flex-start;
    margin-right: 48px;
    min-width:326px;
    height:100%;
    flex-shrink: 1;
    overflow: hidden;
    // transform:scale(var(--scaleiPad));
    transform-origin: top left;
}
:global(.serverApp) .menuRightColContainer{
    margin-left: 10px;
}

.menuLinksContainer{
    padding: 0 0 0 22px;   
    max-height: 100%;
    overflow: scroll;
    /* width: 210px; */
}

.menuModuleContainer{
    max-width: calc(95px * var(--lerpScale));
    width: $menuModuleContainerWidth;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    border-right:1px solid #878E91;
    // height: 100%; * removed because border was running to the bottom
    overflow-y: scroll;
}
@media only screen and (max-aspect-ratio:144/100) {
    /* .menuModuleContainer{
        min-width:76px;
    } */
}


.menuLink{
    font-size: scaleValue(18px,28px);
    // font-weight: scaleValue(400,200);
    font-weight: 400;
    margin:calc(24px * var(--lerpScale)) 0;
    cursor: pointer;
    transition: color 0.25s ease-in-out;
}


span[type="group"] svg{
    // justify-content: space-between;
    display: none;
}

.menuLink:last-child {
    padding-bottom: 100px;
}

.menuLink :global(span){
    display: flex;
    align-items: center;

}
.menuSubContainer{
    height:0;
    overflow: hidden;
}

.menuSubLink{
    padding-left: 25px;
    opacity: 0.75;
}
.menuSubLink:last-child {
    margin-bottom: 0;

}
.menuLink.menuSubLink:last-child {
    padding-bottom: 0;
}


.menuLink:hover {
    color: var(--hover-color) !important;
    
}
.menuLink:hover :global(.themeable) {
    stroke: var(--svg-stroke-hover) !important;
}

.menuLink[open] :global(.rotate180){
    transform: rotate(360deg); 
  }
  .menuLink :global(.rotate180){
    transform: rotate(180deg);
    transition: transform 0.35s ease-in-out;
  }
.menuLink:nth-child(1){
    margin-top: calc(12px * var(--lerpScale));
}

.buttonContainer{
    pointer-events: none;
    position: absolute;
    // top:calc(24px * var(--scaleiPad));
    bottom:var(--sideIndent);
    margin-left:var(--sideIndent);
    display: flex;
    justify-content: flex-start;
    // flex-direction: column;
    align-items: center;
    z-index: 1;
    gap: 18px;
}

:global(.controllerApp) .buttonContainer {
    margin-left: 0;
}

:global(.narrative-holder) .buttonContainer {
    margin-left: var(--sideIndent);
}

.buttonContainer :global(.round-btn) {
    margin-bottom: 0;
    // margin-right:var(--sideIndent);
}


:global(.controllerApp) .buttonContainer{
    z-index: auto;
}

.btn:last-child(){
    margin-bottom: 0;
}
.btnSpacer{
    width:100%;
    height:100%;
    flex:1;
    pointer-events: none;
}

.moduleLink{
    padding: calc(15px * var(--lerpScale)) 0;
    width:75%;
    display: flex;
    align-items: center;
    justify-content: center;   
    cursor: pointer;
    --svg-stroke:var(--primary);
    --svg-stroke-hover:var(--primary-accent);
}
.moduleLink:nth-of-type(1){
    padding-top: 0;
}

.moduleLink :global(.themeable){
    transition: stroke 0.25 ease-in-out;
}
.moduleLink:hover :global(.themeable),
.moduleLink[highlight="true"] :global(.themeable)
{
    stroke:var(--svg-stroke-hover) !important;

}
.moduleLink:nth-child(1){
    margin-top:0
}
.moduleLink :global(img),
.moduleLink svg{
    height:auto;
    width:100%;
    max-width:32px;
}

.moduleLink div {
    display: flex;
    justify-content: center;
}



.menuContainer[overlap] .sideDragger{
    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,1));
}

.sideDragger{
    --svg-stroke: var(--secondary);
    --svg-stroke-hover: var(--primary-accent);
    position: absolute;
    top:0;
    width: var(--dragger-width);
    bottom:0;
    display: flex;
    background-color: rgba(255,255,255,0.25);
    align-items: center;
    cursor: pointer;
    right:0px;
    // border-right: 1px solid rgba(255,255,255,0.8);
    
    // filter: drop-shadow(5px 0px 5px rgba(0,0,0,0));
    transition: background-color 0.15s ease-in-out, filter 2s ease-in-out;
    justify-content: center;
}
.sideDragger svg{
    width:100%;
    height: auto;
    transform: rotate(90deg);
    position: absolute;
}
.sideDragger:hover,
.sideDragger:active{
    background-color: rgba(255,255,255,0.5);    
}
.sideDragger line{
    stroke:var(--svg-stroke)
}
.sideDragger:hover :global(line),
.sideDragger:active :global(line){
    stroke: var(--svg-stroke-hover) !important; 
}
.sideDragger:active{
    background-color: rgba(255,255,255,0.5);
}

.topBarHolder{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:4px;
    display: flex;
    justify-content: space-evenly;
    background-color:rgba(255,255,255,1);
    visibility: hidden;
}
.topBarHolder[loaded="true"]{
    visibility:visible;
}

.bar{
   height:calc(100% - 1px); 
   width:100%; 
//    cursor: pointer;
   gap:1px;
   margin:0 1px 1px 1px;
   background-color:black;
   transition: background-color 0.25s ease-in-out;

}

// .bar:hover{
//     opacity:1;
//     background-color:var(--hover-color) !important;
// }

.bar[active="true"]{
    background-color:var(--active-color)!important;
}

.btnBack{
    position: absolute;
    left:34px;
    top:38px;
}

.btnBookmark:global(.selected) :global(.round-btn) path  {
    fill:var(--svg-stroke)!important;
}

.animBandContainer{
    position: absolute;
    // right:calc(100% - calc(173px * var(--scaleiPad)));
    left:70%;
    height:100%;
    width:100%;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    z-index: 1;
    border-bottom-right-radius: calc(-173px * var(--scaleiPad)); 
    overflow: hidden;
}
.animBand{
    height: 100%;
    position: relative;
}
.animBandCorner{
    height: calc(173px * var(--scaleiPad));
    width:calc(173px * var(--scaleiPad));
    position: absolute;
    right:100%;
    bottom:0;
}


.loaderBG{
    opacity:0;
    background-color: rgba(0,0,0,0.25);
}

.loaderBG :global(svg) {
    opacity:0;
    transform: scale(0.75);
}



.controlsContainer{
    position: absolute;
    overflow: hidden;
    opacity: 0;
    bottom:50px;
    left:calc(100% - 240px);
    min-width: 115px;
    max-width: 170px;
    display: flex;
    flex-direction: column;
    background-color: #EFEFF0;
    border-radius: 3px;
    border-color:rgba(53,56,57,0.34);
    filter: drop-shadow(0 2px 10px  rgba(0,0,0,0.5));
    border-radius: 14px;
    width: 100%;
}
.controllsBtn{
    cursor: pointer;
}
.controlsTop{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    height:32px;
    cursor: pointer;
}

.controlsTop svg{
    width: 32px;
    height: 32px;   
}
.controlsBtm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    border-bottom: 1px solid #fff;
}
.controlsTop:hover :global(svg) :global(rect){
    fill:black;
    // transform: scale(1.2);
    opacity: 0.75;
    transform-origin: center center;
}
@media (hover: hover) {
    .controlsBtm :global(svg:hover) :global(path),
    .controlsBtm :global(svg:hover) :global(line){
        // transform: scale(1.15);
        // transform-origin: center center;
        stroke: var(--svg-stroke-hover);
    }
}
.controlsBtm :global(svg:active) :global(path),
.controlsBtm :global(svg:active) :global(line){
    // transform: scale(1.15);
    // transform-origin: center center;
    stroke: var(--svg-stroke-hover);
}
.controlsBtm svg{
    width:auto;
    height: 67%;
    cursor: pointer;
    margin:4px;
}
.controlsSeparator{
    background-color: #707070;
    opacity: 0.5;
    width: 2px;
    height:50%;
}

.controlsPill {
    height: calc(calc(38px + calc(var(--lerpAspect) * 4px)) * var(--lerpScale));
    background-color: var(--round-btn-background-color);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 1px rgba(0,0,0,0.1);
    transition: all 0.3s ease;

}

.controlsPill svg {
    padding: 0 4px;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.controlsPill svg:first-of-type {
    border-right: 1px solid #e6e6e6;
}

.controlsBtmDrawer{
   overflow: hidden;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   max-width: 173px;
   
}
.controlsMenuContainer{
    max-height: 295px;
    overflow-y: scroll;
    overflow-x:none;
}

.controlsMenuLink{
    font-size: 16px;
    color:#0F3557;
    line-height: 30px;
    cursor: pointer;
    padding:0 11px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  
}
.controlsMenuContainer>.controlsMenuLink:first-of-type{

   padding-top: 11px;
}

.controlsMenuContainer>.controlsMenuLink:last-of-type{
   padding-bottom: 11px;
}

.controlsMenuLink:active{
    color:white;
}
.controlsModuleLinks{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 11px;
    width: calc(100% - 22px);
    max-width: 173px;
    border-top: 1px solid #fff;
    padding: 11px;
    align-items: center;
    justify-items: center;
}

.controlsModuleLink{
    cursor: pointer;
    --svg-stroke:#3C4347;

}

.controlsModuleLink svg{
    height:auto;
    width:100%;
    max-width:32px;
}

@media (hover: hover) {
    .controlsMenuLink:hover{
        opacity:0.75
    }
    .controlsModuleLink:hover{
        opacity: 0.75;
    }
}

.controlsMenuGrabber{
    background-color: #C5CCD3;
    width: 100%;
    height:32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 32px;
    max-height: 32px;

}

.controlsMenuGrabber :global(svg){ transform: rotate(0deg); height: 23px;  pointer-events: none;}
// .controlsMenuGrabber :global(line){ stroke: white; }

.drawControlsContainer{
    // min-width:171px;
    z-index: 2;
}
.drawControlsColorRow{
    display: flex;
    width: calc(100% - 24px);
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 12px 12px 0 12px;
}

.drawControlsColorRowBtm {
    padding-bottom: 10px;
}

.drawControlsColor:first-child{
    // margin-left: 16px;
}
.drawControlsColor{
    width:15px;
    height:15px;
    // margin: 16px 8px;
    cursor: pointer;
    border-radius: 24px;
    border: 1px solid transparent;
}
.drawControlsClearBtn{
    font-weight: 700;
    font-size:16px;
    color:#0F3557;
    --hover-color: var(--primary-accent);
    cursor: pointer;
    // flex: 2;
    width: 100%;
    text-align: right;
}
.drawControlsClearBtn:hover{
    color:var(--hover-color);
}
.drawControlsBrushBtn{
    // margin-left: 8px;
    cursor: pointer;
    opacity:0.25;
    display: flex;
    padding: 0 2px;
    height: 32px;
}
.drawControlsEraseBtn{
    // margin: 6px 8px 10px 8px;
    cursor: pointer;
    opacity:0.25;
    transition:opacity 0.25s ease-in-out;
    padding: 0 2px;
    height: 32px;
}

.controlsPill .drawControlsEraseBtn svg:first-of-type, .controlsPill .drawControlsBrushBtn svg:first-of-type {
    border-right: none;
}

.drawControlsBrushBtn:global(.selected){
    opacity:1;   
}

.drawControlsEraseBtn:global(.selected){
    opacity:1;
}

.drawControlsColorRow[tool="marker"] .drawControlsColor:global(.selected){
    border: 1px solid black;
    border-radius: 24px;
}

:global(.presentationMode) .menuRightColContainer{
    // transition : background-color 1s ease-in-out;
    height: auto;
    max-height: auto;
    overflow: hidden scroll;
    width: 100%;
    margin-right: 0;
    padding-bottom: 40px;
}

:global(.presentationMode) .menuColRight{
    position: absolute;
    right:0;
    top:32%;
    border-radius: 75px 0 0 0;
    bottom:0;
    height: auto;
   
    // transition: border-radius 1s ease-in-out;
}

// :global(.presentationMode)  .menuColRight{
//     padding-left: 82px;
// }

:global(.presentationMode) .sideDragger{
    top:32%;
}
:global(.presentationMode) .menuModuleContainer {
    max-height: 100%;
    overflow-y: scroll;
}

.separator {
    height:0;
    border-right: 1px solid #e6e6e6;
    width: 2px;
    height: scaleValue(38px,42px);
    z-index: 1;
}