.alertContainer{
    position: absolute;
    z-index: 1000;
    background-color: rgba(0,0,0,0.25);
    opacity:0;
}

.alertHolder{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    min-width: 275px;
    background-color: rgba(255,255,255,0.78);
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.35));
    border: 1px solid white;
    padding:5px;
    overflow: hidden;
    align-items: center;
}

.title{
    color:var(--primary);
    font-size:22px; margin:10px;
  }

.message{
    color:var(--primary);
    font-size:14px;
}

.btn{
    color:#2E7EFF;
    margin:5px 10px;
    padding:6px 10px;
    font-size:16px;
    font-weight: 700;
    border-radius: 10px;
    /* border: 1px solid var(--primary); */
    cursor: pointer;
}