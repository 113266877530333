

.container{
    pointer-events: none;
   z-index: 3;
   overflow: hidden;
}
.container:global(.add){
    pointer-events: all;
}
.markupsContainer{
    position: absolute;
    right:0;
    max-height:100%;
    width:286px;
    top:0;
    overflow: auto scroll;
    pointer-events: all;
    margin:  -20px;
    padding: 20px;
}

.markupUIContainer{
    margin-top:26px;
    width: 265px;
    border : 1px solid rgba(135,142,145,.34);
    background:#FFF;
    opacity: 0.95;
    left:75%;
    top:25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // filter:drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.35));
    box-shadow: 0 3px 12px rgba(0,0,0,0.35);
}
.markupHeading{
    color:#3C4347;
    font-size: 16px;
    font-weight: 600;
    margin:21px 16px 12px 16px;
}
.markupComment{
    color:#0F3557;
    font-size: 14px;
    margin:0px 16px 0px 16px;
    overflow-wrap:break-word;
}
.markupUIContainer:first-of-type{
    margin-top: 68px;
}

.uiContainer{
    pointer-events: all;
    position: absolute;
    top:16px;
    right: 16px;
    width :240px;
    height: 36px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(135,142,145,.34);
    border-radius : 18px;
    // filter:drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.35));
    box-shadow: 0 3px 12px rgba(0,0,0,0.35);
    transition:opacity 1s ease-in-out;
}
.uiContainerLoading{
    pointer-events: none;
    opacity: 0.35;
}

.uiBtn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32;
    margin:4px;
}
.uiCenter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex:2;
    color:#3C4347;
    padding: 0 12px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    transition: rotate 0.5s ease-in-out;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.uiCenter span{width: 100%;}
.uiCenterOpen svg {
    transition: rotate 0.5s ease-in-out;
    transform: rotate(180deg);
}

.divider{
    height: 20px;
    width: 1px;
    min-width: 1px;
    background: rgb(112, 112, 112);
}

.uiDropDownContainer{
    margin: 0px 0px;
    left:0;
    font-size: 16px;
    cursor: pointer;
    background: white;
    width:100%;
    position: absolute;
    top:100%;
}

.uiDropDownItem{
    width:100%;
    margin: 10px 0px 10px 10px;
}

.uiDropDownItem:global(.selected){
    font-weight: 600;
}


.newContainer{
    position: absolute;
    width: 265px;
    border : 1px solid rgba(135,142,145,.34);
    background:#EFEFF0;
    opacity: 0.95;
    left:75%;
    top:25%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 2px;
    filter:drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.35));
    overflow: hidden;
}
.newTopRow{
    width: 100%;
    background:rgba(255,255,255,.50);
    height: 23px;
    text-align: center;
    cursor: move;
}
.newInputRow{
    width: 100%;
    flex: 2;
    display: flex;
    padding:0;
}
.newInputRow textarea{
    margin:8px;
    border:none;
    width:100%;
    font-family: 'CerebriSans';
    color:#0F3557;
    font-size: 14px;
    resize: vertical;
    min-height: 100px;
    background: none;
    -webkit-user-select: auto;
}
.newInputRow textarea:focus {
    outline: none !important;
}

.newBtmRow{
    background:rgba(255,255,255,.78);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 7px;
}

.newSubmit{
    color:#2E7EFF;
    font-weight: 600;
    font-size: 16px;
    margin:11px 15px 6px 8px;
    cursor: pointer;
}
.newCancel{
    cursor: pointer;
    color:#FF3B3B;
    font-weight: 600;
    font-size: 16px;
    margin:11px 15px 6px 15px;
    cursor: pointer;
   opacity: 0;
}
