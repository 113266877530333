/* 
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--primary);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
   */
  .loader-container{
      text-align: center;
      transition: stroke 0.35s ease-in-out, fill 0.35s ease-in-out ;
  }

  .loader-status{
      margin-top: 10px;
      font-size: 18px;
      color:white;
      filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.3));
      text-align: center;
  }

  .loader-container path.animateable,
  .loader-container rect.animateable{
    fill:#FF4C55;
    stroke-width: 3px;
    animation: loaderFlicker 1.7s infinite;
  }

  .loader-container path.static-border,
  .loader-container rect.static-border{
    fill: none;
    stroke:#FF4C55;
    stroke-width: 3px;
  }

  .loader-container svg{
    width: 69px;
    height: 69px;
    overflow: visible;
  }

  @keyframes loaderFlicker {
    0%   { opacity:0.35; }
    15%  { opacity:1; }
    50%  { opacity:1; }
    65% { opacity:0.35; }
    100% { opacity:0.35; }
  }
  