@import './funcs.scss';

.container{
    display:flex;
    background-color:white;
    align-content: flex-start;
    align-items: flex-start;
    --left-margin:32px;
    opacity:0;
}

.logo{
    // min-width:44%;
    // max-width: 44%;
    height:scaleValue(48px,71px);
    min-height:scaleValue(48px,71px);
    width: auto;
    position: relative;
    margin-top: scaleValue(44px,61px);
}

.slogan{
    /* margin : 0;71px 50px 34px 31px; */
    font-size: 50px;
    line-height: 60px;
    font-weight: 700;
    color: #0F3557;
    position: relative;
    width: 100%;
    height: auto;
    margin:calc(20px * var(--scaleiPad)) 0;
    pointer-events: none;
    justify-self: baseline;
    // opacity: 0;
}
.slogan p{margin:0;}

.column[open] .slogan{
    opacity: 0;
}
.backgroundVideo,
.backgroundImage{
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    object-fit: cover;
}
.backgroundVideo[open],
.backgroundImage[open]{
    filter:blur(10px) ;
    opacity: 0.4; 
}


/* @media only screen and (max-aspect-ratio:144/100) {
    .container{
        --left-margin:24px;
    }
} */

.column{
    position: relative;
    width:calc(24.1% + calc(-0.1% * var(--lerpAspect)));
    min-height:74%;
    padding-left: 1.25%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    margin-right: 15px;
    // transition: all 2s;flex-shrink: 1;
}

/* @media only screen and (max-aspect-ratio:144/100) {
    .column{
        width: 25%;
    }
} */

.column1{
    // background-color:rgba(239, 239, 240,0.95);
    background: linear-gradient(
-24deg
, rgba(232,233,247,1) 0%, rgba(239,239,240,1) 100%);

    border-radius: 0 0 calc(29px * var(--scaleiPad)) 0;    
    filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.5));
    display: flex;
    flex-direction: column;
    min-width:19%;
    min-height: auto;   ;
    padding-left:2.1%;
    min-height:auto;
    width: 19%;
    // padding-top: scaleValue(44px,61px);
    transition: width 0.35s cubic-bezier(0.455, 0.030, 0.515, 0.955) 0.25s;
    /* padding-top: 5.5%; */
}
.column1:global(.settings){
    width:365px;
}
.column1:global(.search){
    /* transition: width 0.35s ease-in-out 0.25s; */
    width:scaleValue(341px,470px)
}
.column1 .spacer{
    flex:1;
    pointer-events: none;
}
.column1[open]{
    height:100%
}

.column2{
    /* margin: 192px 0 0 96px; */
    position: relative;
    top: 18%;
    opacity: 0;
    left:10px;
    max-height: 82vh;
    overflow-y: scroll;
}

.column3{
    position: relative;
    top: 18%;
    border-left:1px solid #878E91;
    opacity: 0;
    max-height: 82vh;
    overflow-y: scroll;
    
}
.column4{
    position: relative;
    top: 0;
    height:100%;
    opacity: 0;  
}
.column4Container{
    position: relative;
    
    top: 0;
    width: 100%;
    bottom:0;
    height: 100%;
    overflow:hidden scroll;
    
}

.column4ScrollContainer{
    position:relative;
    top:18%;
}
.column4Border{
    border-left:1px solid #878E91;
    left:0;
    position: absolute;
    width:0;
    min-height:74%;
    top:18%;
}

.column1Container{
    display: none;
    position: absolute;
    top: 18%;
    width: 85%;
    z-index: 2;
    /* margin:calc(20px * var(--scaleiPad)) 0; */
}

.column[open] .column1Container{
    display: block;
}

.columnHeading{
    margin-top: 350px;
    font-size: 2em;
}

.columnItem{
    padding: scaleValue(10px,15px) 0px scaleValue(10px,15px) 0; 
    font-size: scaleValue(18px,28px);
    height: scaleValue(25px,35px);
    font-weight: 400;
    // float:left;
    // clear: both;
    cursor: pointer;
    color:#0F3557;
    opacity: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    // font-size: 22px;
}
:global(.presentationMode) .columnItem {
    white-space: normal;
    height: auto;
}
:global(.serverApp) .columnItem {
    cursor: none;
}


.columnItem :global(span){
    height: 85%;
}
.columnItem :global(svg){
    height: 100%;
}
.subColumnItem{
    margin-left:32px;
}

.columnItem:first-of-type{
    padding-top: 0;
}
/* @media only screen and (max-aspect-ratio:144/100) {
    .columnItem{
        font-size: 20px;
        padding: 5px 0px ; 
    }
} */
.columnItem[active="true"]{
    font-weight: 600;
    color:rgba(255, 76, 85, 1);
    
}
@media (hover: hover) {
    .columnItem:hover{
        // opacity: 0.75;
        color:rgba(255, 76, 85, 1)
    }
}
.columnItem:active{
    // opacity: 0.5;
    color:rgba(255, 76, 85, 1)
}


/* 
.btn{
    width:42px;
    height:42px;
    margin:6px 0;
    background:white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
/* @media only screen and (max-aspect-ratio:144/100) {
    .btn{
        width:32px;
        height:32px;
    }
}
@media (hover: hover) {
    .btn:hover{
        background-color:silver;
        transform:scale(1.15)
    }
}
@media (hover: none) {

    .btn:active{
        background-color:silver;
        transform:scale(1.15)
    }
} */ 

.searchContainer{
    display: flex;
    margin-bottom:scaleValue(22px,28px);
}

.searchContainer :global(.round-btn){
    align-self: flex-end;
    margin: 0 20px 0 0;
}

.searchFieldContainer{
    margin-top: scaleValue(44px,61px);
    flex:1;
    justify-content: flex-start;    
    margin-left: 10px;
}

.searchLabel{
    display:block;
    font-weight: 700;
    font-size: scaleValue(16px,20px);
    margin: 0 0 scaleValue(18px,22px) 0 ;
    color:var(--primary)
}
.searchInput[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-color: transparent !important; 
}
.searchInput{
    border-top:0;
    border-bottom:1px solid var(--primary);
    border-left:0;
    border-right: 0;
    border-radius: 0;
    width:80%;
    background-color: transparent  !important;
    font-size: calc( 20px * var(--scaleiPad));
    color:var(--primary);
    -webkit-appearance: none;
}
.searchInput:focus{
    outline: none;
}

:global(.ipad) .searchInput{
    -webkit-user-select: text;
    user-select: text;
}


.searchResultsContainer{
    position :absolute;
    top: 18%;
    left:5%;
    right:5%;
    bottom: 0;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: scroll;
}

.myPropertiesContainer{
    position :absolute;
    top: 16.6%;
    left: 23.5%;
    right:0;
    bottom: 0;
   overflow: scroll;
}
.myPropertiesWrapper{
    position: relative;
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.searchResult{
    color:var(--primary);
    padding: scaleValue(10px,15px) 0 scaleValue(10px,15px) 0; 
    font-size: scaleValue(18px,28px);
    height: scaleValue(25px,35px);
    font-weight: 400;
    cursor:pointer;
    // width: fit-content ;
    width: 33%;
}

.searchResult:hover{
    color:var(--primary-accent)
}


.narrativeSwipe1{
    position: absolute;
    top:0;
    right:100%;
    height:100%;
    width:35%;
    background-color: rgb(239,239,239);
}
.narrativeSwipe2{
    top:0;
    right:100%;
    position: absolute;
    height:100%;
    width:20%;
    background-color: rgb(135,142,145);
}
.narrativeSwipe3{
    top:0;
    right:100%;
    position: absolute;
    height:100%;
    width:10%;
    background-color: rgb(0,195,46);
}

.myProperties{
    border-top: 1px solid #878E91;
    width:100%;
    padding-top: 33px;
    margin-top: 33px;
    font-weight: 600;
}
.myProperties:global(.selected){
    color:rgba(255, 76, 85, 1)
}

.settingsContainer{
    color:var(--primary);
    position:relative;
}
.settingsContainer:hover {
    color:var(--primary-accent)
}
.settingsContainer :global(.round-btn){
    padding: 0px scaleValue(16px,20px);
    width: auto;
    font-size: scaleValue(14px,16px);
    border-radius: scaleValue(16px,21px);
};


.helpContainer{
    // position: absolute;
    // top:32px;
    // right:32px; 
    opacity: 1;
    
}
.helpContainer :global(.round-btn){
    opacity: 0;
    // scale:0.1;
}

.helpContainer[open]{
    pointer-events: none;
  
}

.helpCtaContainer{
    position: absolute;
    top: scaleValue(64px,68px) ; ;
    right:0;
    width:180px;
    color:var(--primary);
    font-size: 18px;
    background-color: white;
    border: 1px rgba(135,142,145, 0.34);
    border-radius: 2px;
    padding:12px 16px;
    white-space: pre;
    filter: drop-shadow(0px 3px 20px rgba(0,0,0, 0.34));
    line-height: 22px;
    text-align: right;
    opacity: 0;
    transform: scale(0.5);
}

.helpCtaArrow{
    position: absolute;
    width: scaleValue(25.4px,29.7px) ; 
    height: scaleValue(25.4px,29.7px) ; 
    border-left: 1px solid rgba(135,142,145, 0.34);
    background-color: white;;
    border-top  :  1px solid rgba(135,142,145, 0.34);
    position: absolute;
    transform: rotate(45deg);
    transform-origin: top right;
    right: 0;
    top:0;
    z-index: -1;  
}

.versionNote {
    position: absolute;
    bottom:30px;
    right:30px;
    z-index: 10;
}

:global(.serverApp) .buttonContainerCol{
 display: none;
}



:global(.presentationMode) .container{
    align-items: flex-end;
}
:global(.presentationMode) .column{
  min-height: 33%;
}
:global(.presentationMode) .column1{
    border-radius: 0  calc(29px * var(--scaleiPad)) 0 0;
    padding-left: 130px;
    padding-bottom: 20px;
}
:global(.presentationMode) .column1Container{
    margin-top: 20px;
    width: calc(85% - 120px);
}
:global(.presentationMode) .column3,
:global(.presentationMode) .column2,
:global(.presentationMode) .column4{
    min-height: 68%;
    max-height: 68%;
    overflow: hidden scroll;
    top:0;
    height: auto;
}
:global(.presentationMode) .column4{ border-left:1px solid #878E91;}
:global(.presentationMode) .column4Border{
    display: none;
}
// :global(.presentationMode) :global(.settings){
//     // display: none;
// }
.presentationLogout{
    position: absolute;
    font-size: 20px;
    right:160px;
    bottom: 32px;
    width:104px;
    height: 42px;
    color:var(--primary);
    background:white;
    border-radius: 21px ;
}

.presentationQuit{
    position: absolute;
    font-size: 20px;
    right:32px;
    bottom: 32px;
    width:104px;
    height: 42px;
    color:var(--primary);
    background:white;
    border-radius: 21px ;
}