@import '../styles/funcs.scss';

.menuContainer{
     pointer-events: all;
    width:scaleValue(38px,42px);
    min-height:scaleValue(39px,40px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: scaleValue(19px,21px);
 
    box-shadow: 0 3px 1px rgba(0,0,0,0.1);
    // filter: drop-shadow(0 3px 1px rgba(0,0,0,0.1));
    // bottom:calc(12px * var(--scaleiPad)); 
    background-color: var(--round-btn-background-color);
    transition: transform 0.1s ease-in-out;
    // flex-direction: column;
    transform-origin: 50% 50%;
    overflow: hidden;
    position: relative;
}

.menuContainer[open]{
    overflow: hidden;
}

.menuContainer[closed]{
    overflow: visible;
}

.menuContainer:global(svg){
    // width:calc(15px * var(--scaleiPad));
    transition: transform 0.25s ease-in-out;
    width:100%;
    height: 100%;
}

.separator{
    height:0;
    border-right: 1px solid #e6e6e6;
    width: 2px;
    height: scaleValue(38px,42px);
    z-index: 1;
}

.btnTools{
    width:scaleValue(38px,42px);
    height:scaleValue(38px,42px); 
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnTools path{
  stroke:var(--svg-stroke);
  transition: all 0.25s ease-in-out
}

.btnTools :global(svg){
    width:100%;
    height: 100%;
}



.menuContainer .btnSub:first-child{
    // padding-top: 10%;
}

@media (hover: hover) {
    // .menuContainer:hover,
    // .btnSub:hover,
    // .btnClose:hover{
    //   transform: scale(1.15) ;
    // }
    .menuContainer .btnTools:hover path,
    .menuContainer .btnClose:hover line,
    .menuContainer .btnSub:hover path,
    .menuContainer .btnSub:hover :global(rect.themeable),
    .menuContainer .btnSub:hover line{
      stroke: var(--svg-stroke-hover) ;
    }
    // .menuContainer:hover:global(.open){
    //     transform: scale(1.15);
    // }
  }

.btnClose{
    // width: 63%;
    text-align: center;
    display: flex;
    justify-content: center;
    // padding-bottom: 10%;
    align-items: center;
    padding: 0 2px;
    min-height:scaleValue(39px,42px);
}
.btnSub{
    width:100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: none;
}
